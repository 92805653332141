<template>
  <div class="home">
    <header class="main-header">
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top" style="margin: 0;">
        <div class="d-flex align-items-center logo-box justify-content-start">
          <!-- Logo -->
          <a class="logo">
            <!-- logo-->
            <div class="logo-lg">
              <span class="light-logo"
                ><img src="@\assets\logo.png" style="margin:0 30px" height="70" alt="logo"
              /></span><span style="font-size:20px;font-Weight:bold">GESTION DE VICTORY-PARTENRS</span>
            </div>
          </a>
        </div>
      </nav>
    </header>
    <section class="py-50">
      <div class="container">
        <div class="row justify-content-center g-0">
          <div class="col-lg-5 col-md-5 col-12">
            <div class="box box-body">
              <div class="content-top-agile pb-0 pt-20">
                <h2 class="text-primary" style="color: red !important">Connexion</h2>
                <router-link :to="{ name: 'HomeP' }">
                  <h6 class="text-primary" style="color: blue !important;text-decoration:underline;cursor:pointer">Connexion Prof &rarr;</h6>
                </router-link>
              </div>
              <div class="p-40">
                <div class="form-group">
                  <div :class="v.user.email.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                    <span class="input-group-text bg-transparent"
                      ><i class="ti-user"></i
                    ></span>
                    <input
                      type="text"
                      v-model="user.email"
                      class="form-control ps-15 bg-transparent"
                      placeholder="Username"
                      @input="v.user.email.$touch()"
                    />
                  </div>
                  <div class="input-errors" v-for="error of v.user.email.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="form-group">
                  <div :class="v.user.password.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                    <span class="input-group-text bg-transparent"
                      ><i class="ti-lock"></i
                    ></span>
                    <input
                      type="password"
                      v-model="user.password"
                      class="form-control ps-15 bg-transparent"
                      placeholder="Password"
                      @input="v.user.password.$touch()"
                    /><br />
                  </div>
                  <div class="input-errors" v-for="error of v.user.password.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="form-group">
                  <div :class="v.user.annee.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                    <span class="input-group-text bg-transparent"
                      ><i class="ti-calendar"></i
                    ></span>
                    <select class="form-select" v-model="user.annee">
                      <option :value="null" disabled>Choisie Annee scolaire</option> 
                      <option
                      :value="m['idannee']"
                        v-for="m in annees"
                        :key="m['idannee']"
                        selected="selected"
                        >{{m.designation}}</option> 
                    </select>
                  </div>
                  <div class="input-errors" v-for="error of v.user.annee.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div> 
                </div>
                <div class="row">
                  <div v-if="error" class="form-error" style="color:red">
                    {{ error }}
                  </div>
                  <!-- /.col -->
                  <div class="col-12 text-center">
                    <button
                      :disabled="loader ? disabled : null"
                      @click="login()"
                      class="btn btn-info w-p100 mt-15"
                    >
                      SIGN IN
                    </button>
                  </div>
                  <div v-if="loader" style="width: 100%; text-align: center">
                    <div class="lds-default-min">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <!-- /.col -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, } from '@vuelidate/validators'
import loginApi from '../api/login';
import AnneeApi from '../api/annees';
let $ = window.$;
let jQuery = window.$;
let bootstrap = window.bootstrap;

export default {
  setup () {//
    return {  v: useVuelidate() }//
  },//
  name: 'Home',
  data() {
    return {
      user:{
        email: "",
        password: "",
        annee: null,
      },
      annees:[],
      error: null,
      loader: false,
    };
  },
  validations () {//
    return {
      user:{
        email: { required },
        password: { required },
        annee: { required },
      },
    }
  },//
  async mounted() {
    if (localStorage.getItem("centre")) {
      this.$router.push({ name: "Admin" });
    }
    await AnneeApi.getAllNoToken((r) => {
      if(r!=null){
        if(r.success==true){
          this.annees = r.data; 
          this.user.annee = this.annees[this.annees.length-1]?this.annees[this.annees.length-1].idannee:null
        }
      }
    });
  },
  methods:{
    login(){
      this.error = null;
      if(this.v.user.$invalid){//
        this.v.user.$touch();//
      }//
      else{
        this.loader = true;
        const data = {
          email: this.user.email,
          password: this.user.password,
        }
        loginApi.login(data,(response)=>{
          if(response){
            if(response.success){
              if(response.role==1){
                localStorage.setItem("id",response.id);
                localStorage.setItem("email",response.email);
                localStorage.setItem("token",response.token);
                localStorage.setItem("role",response.role);
                localStorage.setItem("annee",this.user.annee);
                this.$router.push({ name: "LoginCentre" });
              }
              else{
                localStorage.setItem("id",response.id);
                localStorage.setItem("email",response.email);
                localStorage.setItem("centre",response.centre);
                localStorage.setItem("token",response.token);
                localStorage.setItem("role",response.role);
                localStorage.setItem("annee",this.user.annee);
                this.$router.push({ name: "Admin" });
              }
              this.loader = false;
            }
            else{
              this.error = response.msg;
              this.loader = false;
            }
          }
          else{
            this.loader = false;
          }
        })
      }
    },
  }
}
</script>

<style lang="scss">
@import "../assets/front/css/vendors_css.css";
@import "../assets/front/css/style.css";
@import "../assets/front/css/skin_color.css";

.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
}

.lds-default-min {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-default-min div {
  position: absolute;
  width: 3px;
  height: 3px;
  background: #00baff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default-min div:nth-child(1) {
  animation-delay: 0s;
  top: 18.5px;
  left: 33px;
}
.lds-default-min div:nth-child(2) {
  animation-delay: -0.1s;
  top: 11px;
  left: 31px;
}
.lds-default-min div:nth-child(3) {
  animation-delay: -0.2s;
  top: 5.5px;
  left: 26px;
}
.lds-default-min div:nth-child(4) {
  animation-delay: -0.3s;
  top: 3.5px;
  left: 18.5px;
}
.lds-default-min div:nth-child(5) {
  animation-delay: -0.4s;
  top: 5.5px;
  left: 11px;
}
.lds-default-min div:nth-child(6) {
  animation-delay: -0.5s;
  top: 11px;
  left: 5.5px;
}
.lds-default-min div:nth-child(7) {
  animation-delay: -0.6s;
  top: 18.5px;
  left: 3.5px;
}
.lds-default-min div:nth-child(8) {
  animation-delay: -0.7s;
  top: 26px;
  left: 5.5px;
}
.lds-default-min div:nth-child(9) {
  animation-delay: -0.8s;
  top: 31px;
  left: 11px;
}
.lds-default-min div:nth-child(10) {
  animation-delay: -0.9s;
  top: 33px;
  left: 18.5px;
}
.lds-default-min div:nth-child(11) {
  animation-delay: -1s;
  top: 31px;
  left: 26px;
}
.lds-default-min div:nth-child(12) {
  animation-delay: -1.1s;
  top: 26px;
  left: 31px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
</style>
